<template>
  <div>
    <div
      v-for="(item, index) in translations.newsList"
      :key="index"
    >
      <section-description-photo
        v-if="index % 2 === 0"
        :title="item.title"
        :description="documentToHtmlString(item.description, options)"
        :image="getImgUrl(item.image)"
      />
      <section-description-photo
        v-else
        :title="item.title"
        :description="documentToHtmlString(item.description, options)"
        :image="getImgUrl(item.image)"
        :left="true"
      />
    </div>
  </div>
</template>

<script>
  import SectionDescriptionPhoto from '@/views/sections/reusable/DescriptionPhoto'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_NEWS } from '@/views/news/Index.vue'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import getImgUrl from '@/utils/getImgUrl.js'
  import options from '@/utils/imageInRichTextOptions.js'

  export default {
    name: 'SectionNewsList',
    components: {
      SectionDescriptionPhoto,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_NEWS, [
        'translations',
      ]),
    },
    methods: {
      documentToHtmlString,
      getImgUrl,
    },
    data: function () {
      return {
        options,
      }
    },
  }
</script>
